import { type ToastID } from 'vue-toastification/dist/types/types';
import LoadingToast from '../views/Components/loading-toast.vue'
import {useToast, POSITION} from 'vue-toastification'

const _toast = useToast();

const toast = function(content: any) {
    return _toast({
        component: LoadingToast,
        props: {
            content
        },
        listeners: {}
    }, {
        position: POSITION.BOTTOM_RIGHT,
        timeout: false,
        icon: false,
        closeButton: false
    })
}

toast.toast = _toast;

toast.update = function(id: ToastID, {content}: {content: any}) {
    return _toast.update(id, {content: {
        component: LoadingToast,
        props: {content},
        listeners: {}
    }})
}

toast.simple = function(content: any) {
    return _toast(content, {
        position: POSITION.BOTTOM_RIGHT,
    })
}

toast.dismiss = _toast.dismiss;
toast.error = _toast.error;

export { toast, POSITION }