import { Units } from '@busy-human/amt-library'

export const statusMap = Units.StatusTitleMap;

// export const statusMap = {
//     "available": "Available",
//     "Pending" : "Pending",
//     "assigned": "Assigned",
//     "weather-check": "Weather Check",
//     "accepted": "Accepted",
//     "en-route": "En Route",
//     "at-scene": "At Scene",
//     "transporting": "Transporting",
//     'at-receiving': "At Receiving",
//     "en-route-to-base": "En Route to Base",
//     "flight-completed-no-transport": "Flight Completed - No Transport",
//     "flight-completed-patient-transport": "Flight Completed - Patient Transport",
//     "en-route-to-fuel": "En Route to Fuel",
//     "landed-at-fuel": "Landed at Fuel",
//     "transporting-by-ground": "Transporting by ground",
//     "declined": "Declined",
//     "cancelled": "Cancelled",
//     "15-minute-delay": "15 Minute Delay",
//     "oos-maintenance-scheduled": "OOS: Maintenance - Scheduled",
//     "oos-maintenance-unscheduled": "OOS: Maintenance - UnScheduled",
//     "oos-decon": "OOS: Decon",
//     "pending-occ-approval": "Pending OCC Approval",
//     "oos-no-medic": "OOS: No Medic",
//     "oos-no-nurse": "OOS: No Nurse",
//     "oos-no-provider": "OOS: No Provider",
//     "oos-no-pilot": "OOS: No Pilot",
//     "oos-crew-rest": "OOS: Crew Rest",
//     "missed-flight": "Missed Flight",
//     "aborted-flight": "Aborted Flight"
//   }

export const nextButtonList = [Units.UnitStatus.Available, Units.UnitStatus.Assigned, Units.UnitStatus.WeatherCheck, Units.UnitStatus.Accepted, Units.UnitStatus.EnRoute, Units.UnitStatus.AtScene,
  Units.UnitStatus.Transporting, Units.UnitStatus.AtReceiving, Units.UnitStatus.EnRouteToBase, Units.UnitStatus.NoTransport]

export const toNext: Record<Units.UnitStatus, Units.UnitStatus | undefined> = {
  [Units.UnitStatus.StandbyAssigned]: Units.UnitStatus.StandbyAccepted,
  [Units.UnitStatus.StandbyAccepted]: Units.UnitStatus.StandbyGo,
  [Units.UnitStatus.StandbyGo]: Units.UnitStatus.EnRoute,
  [Units.UnitStatus.Available]: Units.UnitStatus.Assigned,
  [Units.UnitStatus.Assigned]: Units.UnitStatus.WeatherCheck,
  [Units.UnitStatus.WeatherCheck]: Units.UnitStatus.Accepted,
  [Units.UnitStatus.Accepted]: Units.UnitStatus.EnRoute,
  [Units.UnitStatus.EnRoute]: Units.UnitStatus.AtScene,
  [Units.UnitStatus.AtScene]: Units.UnitStatus.Transporting,
  [Units.UnitStatus.Transporting]: Units.UnitStatus.AtReceiving,
  [Units.UnitStatus.AtReceiving]: Units.UnitStatus.EnRouteToBase,
  [Units.UnitStatus.EnRouteToBase]: Units.UnitStatus.NoTransport,
  [Units.UnitStatus.EnRouteToFuel]: Units.UnitStatus.LandedAtFuel,
  [Units.UnitStatus.LandedAtFuel]: Units.UnitStatus.EnRoute,
  [Units.UnitStatus.TransportingByGround]: Units.UnitStatus.AtReceiving,
  [Units.UnitStatus.Declined]: undefined,
  [Units.UnitStatus.Cancelled]: undefined,
  [Units.UnitStatus.CancelledRTB]: undefined,
  [Units.UnitStatus.NoTransport]: undefined,
  [Units.UnitStatus.PatientTransport]: undefined,
  [Units.UnitStatus.Delay]: undefined,
  [Units.UnitStatus.MaintenanceScheduled]: undefined,
  [Units.UnitStatus.MaintenanceUnscheduled]: undefined,
  [Units.UnitStatus.NotScheduled]: undefined,
  [Units.UnitStatus.Decon]: undefined,
  [Units.UnitStatus.OCCAproval]: undefined,
  [Units.UnitStatus.NoMedic]: undefined,
  [Units.UnitStatus.NoNurse]: undefined,
  [Units.UnitStatus.NoProvider]: undefined,
  [Units.UnitStatus.NoPilot]: undefined,
  [Units.UnitStatus.CrewRest]: undefined,
  [Units.UnitStatus.MissedFlight]: undefined,
  [Units.UnitStatus.AbortedFlight]: undefined,
  [Units.UnitStatus.Pending]: undefined,
  [Units.UnitStatus.Unknown]: undefined,
  [Units.UnitStatus.Rescheduled]: undefined
};