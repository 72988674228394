import { defineComponent as _defineComponent } from 'vue'
import { useIncidents } from '@/store/useIncidents';
import { useTasks } from '@/store/useTasks';
import { computed, watch } from 'vue';
import Modal from '@/views/Components/Modal.vue';
import { useNotifications } from '@/store/useNotifications';


export default /*@__PURE__*/_defineComponent({
  __name: 'task-warning-overlay',
  setup(__props, { expose: __expose }) {
  __expose();

const taskStore = useTasks();
const incidentStore = useIncidents();
const notificationStore = useNotifications();

const hasPendingTasks = computed(() => taskStore.pendingTasks.length > 0);
watch(hasPendingTasks, (hasTasks, prevHasTasks) => {
    if(hasTasks && !prevHasTasks) {
        // const nId = useUsers().currentUser?.notificationIdxs?.default ?? 0;
        notificationStore.playNotification(false, `Incidents have pending tasks\n${taskStore.pendingTasks.map(t => incidentStore.incidents.get(t.incidentId)?.number ?? "Unknown").join(", ")}`);
    }
});

const incidentsAndTasks = computed(() => {
    const incidents = Array.from(new Set(taskStore.pendingTasks.map(t => t.incidentId)));
    return incidents.map(i => {
        return [
            incidentStore.incidents.get(i)?.number || '',
            taskStore.pendingTasks.filter(t => t.incidentId === i)
        ] as const;
    })
});

function acknowledge() {
    taskStore.acknowledgeAllTasks();
}


const __returned__ = { taskStore, incidentStore, notificationStore, hasPendingTasks, incidentsAndTasks, acknowledge, get useIncidents() { return useIncidents }, get useTasks() { return useTasks }, computed, watch, Modal, get useNotifications() { return useNotifications } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})