import { Company, DataInstance, LandingZones, Messages } from "@busy-human/amt-library";
import { defineStore } from "pinia";
import { ref, watch, computed } from "vue";
import { useAuth } from "./useAuth";

import { useIncidents } from "./useIncidents"
import { useUnits } from "./useUnits";
import { useUsers } from "./useUsers";
import { statusMap } from "@/views/Dashboard/statusMapping";
import emitter, { events } from "@/events";
import { debounce, isEqual, xorWith } from "lodash";
import { playSound } from "@/views/alerts";
import { useMessageUnreadCount } from "@/composables/useMessageUnreadCount";
import { useUnitMessages } from "@/composables/useUnitMessages";

type NotificationCounts = {
    messages: number
    incidentStatusChange: number
    unitStatusChange: number
    unitWeatherChange: number
    amtIncident: number
    restTimers: number
    q15StatusCheck: number
}

type Notification = {
    sender: string
    message: string
    timestamp: number | string | Date
    route?: string
    params?: Object

    remove?: Function
    click?: Function
}

export const useNotifications = defineStore('notifications', () => {
    const auth = useAuth();
    const units = useUnits()
//     const messages = useMessages()
    const { messages } = useMessageUnreadCount();
    const incidents = useIncidents()
    const users = useUsers()

    const loaded = ref(false);

    const history = ref<{notification: string, repeatCount: number, at: Date}[]>([]);

    const messageNotifications = ref<Notification[]>([])
    const incidentStatusChangeNotifications = ref<Notification[]>([])
    const weatherChangeNotifications = ref<Notification[]>([])
    const unitStatusChangeNotifications = ref<Notification[]>([])
    const amtIncidentNotifications = ref<Notification[]>([])

    const restTimerNotifications = ref<Notification[]>([])
    const q15StatusCheckNotifications = ref<Notification[]>([])

    const notificationCounts = ref<NotificationCounts>({
        messages: 0,
        incidentStatusChange: 0,
        unitStatusChange: 0,
        unitWeatherChange: 0,
        amtIncident: 0,
        restTimers: 0,
        q15StatusCheck: 0
    })

    const unitUserAckNotifications = computed<Notification[]>(() => incidents.unitUserAcks.map((a, i) => ({
        sender: a.name,
        message: `${a.name} acknowledged incident ${a.incidentNumber}`,
        timestamp: a.timestamp,
        remove: () => incidents.unitUserAcks.splice(i)
    })));

    const notifications = computed(() => [...unitUserAckNotifications.value, ...messageNotifications.value, ...incidentStatusChangeNotifications.value, ...weatherChangeNotifications.value, ...unitStatusChangeNotifications.value, ...amtIncidentNotifications.value, ...restTimerNotifications.value, ...q15StatusCheckNotifications.value])

    const notificationIdxs = computed(() => users.currentUser?.notificationIdxs || { default: 0, amtIncident: 0 })

    const playNotification = debounce((isAMTNotif: boolean, notif: string | null) => {
        if(notif) {
            const prev = history.value.at(0);
            if(prev && prev.notification === notif) {
                prev.repeatCount += 1;
            } else {
                history.value.unshift({notification: notif, repeatCount: 0, at: new Date()});
            }
        }
        if (isAMTNotif) {
            playSound(notificationIdxs.value.amtIncident)
        } else {
            playSound(notificationIdxs.value.default)
        }
    })

    watch(unitUserAckNotifications, (n, o) => {
        if(n.length > o.length) {
            playNotification(false, "Crew/Pilot acknowledged assignment")
        }
    });

    function alertForQ15() {
        let now = Date.now()
        let interval = Math.round(now.valueOf() / 1000) % 60
        // console.log("Interval", interval)
        if (interval === 0) {
            playNotification(false, "Q15 Alert")
        }
    }

    function doQ15Check() {
        if (notificationCounts.value.q15StatusCheck <= 0) {
            events.off('tick', alertForQ15)
            events.off('Q15CHECK:ACK', doQ15Check)
        }
    }

    const q15IntervalCheck = () => {
        if (notificationCounts.value.q15StatusCheck > 0) {
            events.on('tick', alertForQ15)
            events.on('Q15CHECK:ACK', doQ15Check)
        } else {
            events.off('tick', alertForQ15)
            events.off('Q15CHECK:ACK', doQ15Check)
        }

        
    }

    const waitingPromises = ref<(() => void)[]>([])
    watch(() => auth.companyId, async companyId => {
        loaded.value = false;

        if (companyId) {
            loaded.value = true
        }
        loadAllNotifications()
    }, { immediate: true })


    //Notification Loaders
    function loadAllNotifications() {
        loadMessageNotifications()
        loadIncidentStatusChangeNotifications()
        loadWeatherChangeNotifications()
        loadUnitChangeNotifications()
        loadAMTIncidentNotifications()
        loadQ15StatucCheckNotifications()
    }

    function loadMessageNotifications() {
        const tmpNotifications: Notification[] = []
        let tmpCounts = 0

        //========= Message Notifications ==============
        let oldestUnread: Record<string, Messages.Message> = {}
        messages.value.forEach((message) => {
            if (!oldestUnread[message.unitId])
                oldestUnread[message.unitId] = message
            else if (oldestUnread[message.unitId].timestamp.toDate() < message.timestamp.toDate())
                oldestUnread[message.unitId] = message
        })
        for (let id in oldestUnread) {
            let message = oldestUnread[id]
            let sendingUnit = units.units.get(message.unitId)
            const unitName = sendingUnit?.name || "Deleted Unit";
            let notification = {
                sender: `${unitName} sent a message`,
                message: message.message,
                timestamp: message.timestamp.toDate(),
                route: '/messaging',
                params: { unitID: message.unitId },
                remove: () => {
                    return useUnitMessages(message.unitId, false).markMessagesRead()
                }
            }

            tmpNotifications.push(notification)
            tmpCounts += 1
        }

        if (tmpCounts > notificationCounts.value.messages || (tmpCounts === notificationCounts.value.messages && xorWith(messageNotifications.value, tmpNotifications, isEqual).length > 0)) {
            playNotification(false, tmpNotifications.map(t => t.sender).join(", "));
        }

        messageNotifications.value = tmpNotifications
        notificationCounts.value.messages = tmpCounts
    }

    function loadIncidentStatusChangeNotifications() {
        const tmpNotifications: Notification[] = []
        let tmpCounts = 0

        let incidentChangeArray = Array.from(incidents.incidentChanges, ([key, value]) => ({ ...value }))
        incidentChangeArray.forEach((incidentChange, idx) => {
            let sendingUnit = units.units.get(incidentChange.unit || '')
            let sendingUser = users.users.get(incidentChange.userId)

            let sendingUnitName = sendingUnit?.name || ''
            let sendingUserName = sendingUser?.name?.fullName || sendingUser?.name?.firstName || ''

            let sender = incidentChange.status == 'declined' ? `${sendingUnitName || sendingUserName || "Deleted User"} declined ${incidentChange.incidentNumber}` : `${sendingUnitName || sendingUserName || "Deleted User"} updated ${incidentChange.incidentNumber}`
            let message = incidentChange.status == 'declined' ? `Incident declined due to "${incidentChange.turndownReason}"` : `Incident status changed to ${statusMap[incidentChange.status]} by ${sendingUserName || "Deleted User"}`

            let notification: Notification = {
                sender: sender,
                message: message,
                timestamp: incidentChange.start,
                route: '/dashboard',
                remove: function (all = true) {
                    incidents.removeIncidentChange(incidentChange.incidentId)
                    if (incidentChange.companyNotified === false && incidentChange.logIdx) {
                        let incident = incidents.incidents.get(incidentChange.incidentId)
                        if (incident && incident.statusLog) {
                            let incidentStatusLog = incident.statusLog
                            if (all) {
                                for (let i = 0; i < incidentStatusLog.length; i++) {
                                    incidentStatusLog[i].companyNotified = true
                                }
                            } else
                                incidentStatusLog[incidentChange.logIdx].companyNotified = true

                            incidents.updateIncident(incidentChange.incidentId, { statusLog: incidentStatusLog }).then(() => {
                                incidents.removeIncidentChange(incidentChange.incidentId)
                            })
                        }
                    }
                }
            }
            tmpNotifications.push(notification)
            tmpCounts += 1
        })

        if (tmpCounts > notificationCounts.value.incidentStatusChange || (tmpCounts === notificationCounts.value.incidentStatusChange && xorWith(incidentStatusChangeNotifications.value, tmpNotifications, isEqual).length > 0)) {
            playNotification(false, tmpNotifications.map(t => `${t.sender}\n${t.message}`).join(", "));
        }

        incidentStatusChangeNotifications.value = tmpNotifications
        notificationCounts.value.incidentStatusChange = tmpCounts
    }

    function loadWeatherChangeNotifications() {
        const tmpNotifications: Notification[] = []
        let tmpCounts = 0

        let weatherChangesArray = Array.from(units.weatherChanges, ([key, value]) => ({ ...value }))
        weatherChangesArray.forEach(unit => {
            let message = `${unit.name} changed weather status to ${unit.weather}`
            let notification = {
                sender: unit.name,
                message,
                timestamp: new Date(),
                route: '/dashboard',
                remove: function () {
                    units.removeWeatherChange(unit.$id)
                }
            }
            tmpNotifications.push(notification)
            tmpCounts += 1
        })

        if (tmpCounts > 0 && (tmpCounts > notificationCounts.value.unitWeatherChange || (tmpCounts === notificationCounts.value.unitWeatherChange && xorWith(weatherChangeNotifications.value, tmpNotifications, isEqual).length > 0))) {
            playNotification(false, tmpNotifications.map(t => t.message).join(", "));
        }

        weatherChangeNotifications.value = tmpNotifications
        notificationCounts.value.unitWeatherChange = tmpCounts
    }

    function loadUnitChangeNotifications() {
        const tmpNotifications: Notification[] = []
        let tmpCounts = 0

        let statusChangeArray = Array.from(units.statusChanges, ([key, value]) => ({ ...value }))
        statusChangeArray.forEach((unit) => {
            if (unit.statusChangedBy !== users.currentUser?.$id) {
                const unitName = unit?.name;
                let notification = {
                    sender: `${unitName} updated status`,
                    message: `status changed to ${statusMap[unit.status]}`,
                    timestamp: unit.statusTimeStamp,
                    route: '/dashboard',
                    remove: function () {
                        units.removeStatusChange(unit.$id)
                    }
                }
                tmpNotifications.push(notification)
                tmpCounts += 1
            }
        })

        if (tmpCounts > notificationCounts.value.unitStatusChange || (tmpCounts === notificationCounts.value.unitStatusChange && xorWith(unitStatusChangeNotifications.value, tmpNotifications, isEqual).length > 0)) {
            playNotification(false, tmpNotifications.map(t => `${t.sender}\n${t.message}`).join(", "));
        }

        unitStatusChangeNotifications.value = tmpNotifications
        notificationCounts.value.unitStatusChange = tmpCounts
    }

    function loadAMTIncidentNotifications() {
        const tmpNotifications: Notification[] = []
        let tmpCounts = 0

        incidents.amtIncidents.forEach((amtIncident, idx) => {
            if (amtIncident.active) {
                let notification = {
                    sender: `An incident has been created by AMT User`,
                    message: `Click here to View Incident and Contact User`,
                    timestamp: amtIncident.timeCreated,
                    route: '/dashboard',
                    click: function () {
                        emitter.emit("AMTNOTE:OPENED", amtIncident.$id)
                    },
                }
                tmpNotifications.push(notification)
                tmpCounts += 1
            }
        })

        if (tmpCounts > notificationCounts.value.amtIncident || (tmpCounts === notificationCounts.value.amtIncident && xorWith(amtIncidentNotifications.value, tmpNotifications, isEqual).length > 0)) {
            playNotification(true, tmpNotifications.map(t => t.sender).join(", "));
        }

        amtIncidentNotifications.value = tmpNotifications
        notificationCounts.value.amtIncident = tmpCounts
    }

    const tickerActive = ref(false);
    const unitsOnRest = ref<string[]>([])
    function loadRestTimers() {

        restTimerNotifications.value = []
        notificationCounts.value.restTimers = 0

        let restTimersArray = Array.from(units.restTimers, ([key, value]) => (key))

        if (restTimersArray.length > 0) {
            if (tickerActive.value) {
                events.off('tick', checkTimerExpired)
            }
            unitsOnRest.value = restTimersArray

            events.on('tick', checkTimerExpired)
            tickerActive.value = true
        } else {
            events.off('tick', checkTimerExpired)
            tickerActive.value = false
        }

        function checkTimerExpired() {
            let now = Date.now()
            unitsOnRest.value.forEach((unitId) => {
                let timeStamp = units.restTimers.get(unitId)
                if (timeStamp) {
                    let millis = new Date(timeStamp).valueOf()
                    if (now > millis) {
                        unitsOnRest.value.splice(unitsOnRest.value.indexOf(unitId, 1))
                        let unit = units.units.get(unitId)
                        if (unit) {
                            let notification = {
                                sender: units.units.get(unitId)?.name || "Deleted Unit",
                                message: `Crew Rest Timer Expired`,
                                timestamp: timeStamp,
                                route: '/dashboard',
                                remove: function () {
                                    units.removeRestTimer(unitId)
                                }
                            }
                            restTimerNotifications.value.push(notification)
                            notificationCounts.value.restTimers += 1
                            playNotification(false, `${notification.sender}\n${notification.message}`);
                        }
                    }
                }
            })

            if (unitsOnRest.value.length === 0) {
                events.off('tick', checkTimerExpired)
                tickerActive.value = false
            }
        }
    }

    function loadQ15StatucCheckNotifications() {
        const tmpNotifications: Notification[] = []
        let tmpCounts = 0

        let unitsPendingAck = Array.from(units.pendingAcknowledgement, ([key, value]) => ({ ...value }))
        unitsPendingAck.forEach(unit => {
            //Should be true regardless but doesn't hurt to check
            if (unit.pendingAcknowledge) {
                let notification = {
                    sender: `Unit has been in status ${statusMap[unit.status]} for 15+ min`,
                    message: `Click here to Acknowledge`,
                    timestamp: unit.statusTimeStamp,
                    route: '/dashboard',
                    click: function () {
                        emitter.emit("Q15CHECK:OPENED", unit.$id)
                    },
                }
                tmpNotifications.push(notification)
                tmpCounts += 1
            }
        })

        if (tmpCounts > notificationCounts.value.q15StatusCheck || (tmpCounts === notificationCounts.value.q15StatusCheck && xorWith(q15StatusCheckNotifications.value, tmpNotifications, isEqual).length > 0)) {
            playNotification(false, tmpNotifications.map(t => t.sender).join(", "))
        }

        q15StatusCheckNotifications.value = tmpNotifications
        notificationCounts.value.q15StatusCheck = tmpCounts

        q15IntervalCheck()
    }

    function clearNotifications() {
        notifications.value.forEach(n => {
            if (n.remove) n.remove(true);
        })
    }

    function waitForReady() {
        if (!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
    }


    watch(() => messages.value, async messages => {
        if (loaded.value)
            loadMessageNotifications()
    }, { immediate: true, deep: true })

    watch(() => incidents.incidentChanges, async incidentChanges => {
        if (loaded.value)
            loadIncidentStatusChangeNotifications()
    }, { immediate: true, deep: true })

    watch(() => units.weatherChanges, async weatherChanges => {
        if (loaded.value)
            loadWeatherChangeNotifications()
    }, { immediate: true, deep: true })

    watch(() => units.statusChanges, async statusChanges => {
        if (loaded.value)
            loadUnitChangeNotifications()
    }, { immediate: true, deep: true })

    watch(() => units.restTimers, async restTimers => {
        if (loaded.value)
            loadRestTimers()
    }, { immediate: true, deep: true })

    watch(() => units.pendingAcknowledgement, async pendingAcknowledgement => {
        if (loaded.value)
            loadQ15StatucCheckNotifications()
    }, { immediate: true, deep: true })

    return { notifications, notificationCounts, clearNotifications, loaded, waitForReady, playNotification, history }
}) 