import { CallRequest, Company, DataInstance, Units } from "@busy-human/amt-library";
import { defineStore } from "pinia";
import { computed, type MaybeRefOrGetter, ref, toValue, watch } from "vue";
import { useAuth } from "./useAuth";
import { useOOSReports } from "./useOOSReports";
import { useIncidents } from "./useIncidents";
import { ISOTimestamp } from "@busy-human/gearbox";
import emitter from "@/events";

export const useUnits = defineStore('units', () => {
    const auth = useAuth();
    const units = ref(new Map<string, DataInstance<Units.Unit>>());

    const callRequests = ref(new Map<string, DataInstance<CallRequest.Model>>());

    const weatherChanges = ref(new Map<string, DataInstance<Units.Unit>>())
    const statusChanges = ref(new Map<string, DataInstance<Units.Unit>>())
    const restTimers = ref(new Map<string, ISOTimestamp>())

    const pendingAcknowledgement = ref(new Map<string, DataInstance<Units.Unit>>())
    
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([])

    watch(() => auth.companyId, async companyId => {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

		units.value.clear();
		callRequests.value.clear();
		weatherChanges.value.clear();
		statusChanges.value.clear();
		restTimers.value.clear();
		pendingAcknowledgement.value.clear();

        if(companyId) {
            const collection = Company.Companies.subCollectionEntity(companyId, 'units')
            const subset = collection.all();
            subset.onUpdate(ev => {
                // console.log("UNIT UPDATES:", ev);

                //Status/Weather Changes
                statusChanges.value.clear()
                weatherChanges.value.clear()
                for(const unit of subset.dataItems()) {
                    let oldUnit = units.value.get(unit.$id)
                    if(oldUnit){
                        if(oldUnit.weather !== unit.weather){
                            weatherChanges.value.set(unit.$id, unit)
                        }
                        if(oldUnit.status !== unit.status){
							if(oldUnit.assigned !== unit.assigned && !unit.assigned) {
								console.log("Unit did unassign");
							}
                            statusChanges.value.set(unit.$id, unit)
                        }
                    }
                }

                units.value.clear();
                restTimers.value.clear()
                pendingAcknowledgement.value.clear()
                for(const unit of subset.dataItems()) {
                    if(unit.restTimerExpiration){
                        restTimers.value.set(unit.$id, unit.restTimerExpiration)
                    }

                    if(unit.pendingAcknowledge){
                        pendingAcknowledgement.value.set(unit.$id, unit)
                    }
                    units.value.set(unit.$id, unit);
                }
            });

            const requestCollection = Company.Companies.subCollectionEntity(companyId, "callRequests").all();
            requestCollection.onUpdate(() => {
                callRequests.value.clear();

                for(const req of requestCollection.items) {
                    callRequests.value.set(req.$id, req.data());
                }
            });

            unsub.value = () => {
                subset.cleanup();
                requestCollection.cleanup();
            }
            await subset.listen();
            await requestCollection.listen();
        }
        waitingPromises.value.forEach(res => res());
        waitingPromises.value.length = 0;
        loaded.value = true;
    }, {immediate: true})

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
        else return Promise.resolve()
    }

    function removeStatusChange(unitId: string){
        statusChanges.value.delete(unitId)
    }

    function removeWeatherChange(unitId: string){
        weatherChanges.value.delete(unitId)
    }

    function removeRestTimer(unitId: string){
        restTimers.value.delete(unitId)
    }

    async function doAcknowledge(unitId: string) {
        if(!auth.companyId) throw new Error("no companyId!");

        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');
        await collection.updateDoc(unitId, {pendingAcknowledge: false})
        emitter.emit("Q15CHECK:ACK")
    }

    async function updateUnit(unitId: string, unitDetails: Partial<Units.Unit>){
        if(!auth.companyId) throw new Error("no companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');
        
        await collection.updateDoc(unitId, unitDetails)
    }

    async function addUnit(unitData: Units.Unit) {
        if(!auth.companyId) throw new Error("no companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');
        
        const res = await collection.add(unitData);
        return res.$id;
    }

    async function deleteUnit(unitId: string) {
        if(!auth.companyId) throw new Error("no companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');
        
        await collection.deleteDoc(unitId);

        const incidents = await Company.Companies.subCollectionEntity(auth.companyId, 'incidents')
            .subset((_ref, query) => query.where('assignedUnit', '==', unitId).toQuery()).fetch();
        
        await Promise.allSettled(incidents.map(i => i.update({assignedUnit: null})));

    }

    async function removeUsersFromUnits(unitUsers: Pick<Units.Unit, typeof Units.uniqueFields[number]>, ignoreUnitId?: string) {
        if(!auth.companyId) throw new Error("no companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');

        const _normalize = (field: string | {id: string | null} | null | undefined) => 
                typeof field === 'string' ? field : 
                field ? field.id : null; 
        
        await Promise.all([...units.value.values()].map(async unit => {
            if(unit.$id === ignoreUnitId) return;

            let hasChanges = false;
            const changes: Partial<typeof unitUsers> = {};

            for(const field of Units.uniqueFields) {
                const val1 = _normalize(unitUsers[field]);
                const val2 = _normalize(unit[field]);
                if(val2 && val1 === val2) {
                    hasChanges = true;
                    changes[field] = null;
                }
            }

            if(hasChanges) {
                await collection.updateDoc(unit.$id, changes);
            }
        }));
    }

    async function removeSingleUserFromUnits(userId: string) {
        if(!auth.companyId) throw new Error("no companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');

        const _normalize = (field: string | {id: string | null} | null | undefined) => 
            typeof field === 'string' ? field : 
            field ? field.id : null; 
        
        await Promise.all([...units.value.values()].map(async unit => {
            const changes: Partial<Pick<Units.Unit, typeof Units.uniqueFields[number]>> = {};
            let hasChanges = false;
            for(const field of Units.uniqueFields) {
                const val = _normalize(unit[field]);
                if(val === userId) {
                    changes[field] = null;
                    hasChanges = true;
                }
            }
            if(hasChanges) {
                await collection.updateDoc(unit.$id, changes);
            }
        }));
    }

    // TODO: Have a backend function for this rather than doing it locally
    async function updateStatus(unitId: string, status: Units.UnitStatus, incidentId?: string) {
        const oosStore = useOOSReports();
        if(!auth.companyId) throw new Error("no companyId!");
        const unit = units.value.get(unitId);
        if(!unit) throw new Error(`Unit ${unitId} not found`);
        if(unit.status === status) return;

        if(unit.oosId) {
            await oosStore.endReport(unit.oosId);
        }

        let oosId: string | null = null;
        if(Units.isOOSStatus(status)) {
            oosId = await oosStore.addReport(unitId, status);
        }

        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'units');

        const unitChanges: Partial<Units.Unit> = {
            status,
            oos: Units.isOOSStatus(status),
            statusTimeStamp: new Date().toISOString(),
            statusChangedBy: auth.currentUID,
            oosId,
            assigned: (typeof incidentId !== 'undefined') ? !!incidentId : unit.assigned
        }

        //If the crew isn't going into the rest status, clear the rest timer
        if(status !== Units.UnitStatus.CrewRest){
            unitChanges.restTimerExpiration = ""
        }

        await collection.updateDoc(unitId, unitChanges)
    }

    function outOfService(unit: DataInstance<Units.Unit>) {
        return !!unit.oos;
    }

    function incidentHasUnit(unit: DataInstance<Units.Unit>) {
        return useIncidents().unitIncident.has(unit.$id);
    }

    async function requestCallQueue(unitId: string, incidentId: string) {
        if(!auth.companyId) throw new Error("Not signed in");

        const collection = Company.Companies.subCollectionEntity(auth.companyId, "callRequests");

        await collection.add({
            unitId, incidentId, callerId: auth.currentUID
        });
    }

    const unitsAvailable = computed(() => [...units.value]
        .filter(([_key, unit]) => !outOfService(unit) && !incidentHasUnit(unit))
        .map(([a, b]) => b));

    const unitsUnavailable = computed(() => [...units.value]
        .filter(([_key, unit]) => outOfService(unit) && !incidentHasUnit(unit))
        .map(([a, b]) => b));

    return { 
        units, 
        
        statusChanges, 
        weatherChanges, 
        restTimers, 
        pendingAcknowledgement,

        loaded,  
        waitForReady, 
        
        addUnit,
        deleteUnit,
        updateUnit, 
        updateStatus, 
        
        unitsAvailable, 
        unitsUnavailable, 
        
        removeWeatherChange, 
        removeStatusChange, 
        removeRestTimer,
        doAcknowledge,
        removeUsersFromUnits,
        removeSingleUserFromUnits,

        requestCallQueue,
        callRequests
    }
});

export function useIncidentCallRequests(incidentId: MaybeRefOrGetter<string>) {
    const incidentCallRequests = computed(() => {
        return [...useUnits().callRequests.values()].filter(req => req.incidentId === toValue(incidentId))
    });

    const unitRequestedIncident = (unitId: string) => incidentCallRequests.value.some(req => req.unitId === unitId);
    const unitDeclinedIncident = (unitId: string) => incidentCallRequests.value.some(req => req.unitId === unitId && req.status === 'declined');

    return { incidentCallRequests, unitRequestedIncident, unitDeclinedIncident }
}