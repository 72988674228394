import { Messages } from "@busy-human/amt-library";
import { DataInstance } from "@busy-human/gearbox";
import mitt from "mitt";

type Events = {
    tick: void
    'AMTNOTE:OPENED': string
    'Q15CHECK:OPENED': string
    'Q15CHECK:ACK': void
    'MESSAGE:RECEIVED': DataInstance<Messages.Message>
}
const emitter = mitt<Events>();
export {emitter as events};
export default emitter;