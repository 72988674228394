// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/functions";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";
// import admin from "firebase-admin";
// import {Globals} from "@busy-human/dml-library/dist/js/lib";

import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

import {Globals} from "@busy-human/gearbox";
export const app = initializeApp( CONFIG.firebase.config );

// @ts-ignore firebase-emulators-plugin does not have types
import {install as installEmulators} from "@busy-human/firebase-emulators-plugin/web";


// console.log(firebase.firestore())
export const auth = getAuth(app);
if(FIREBASE_AUTH_EMULATOR_HOST) {
    const url = new URL(`http://${FIREBASE_AUTH_EMULATOR_HOST}`);
    connectAuthEmulator(auth, `${url.protocol}//${url.host}`);
    console.log(`%c[Firebase] Using auth emulator ${url}`, "color: red");
}
export const realFunctions = getFunctions(app);
if(FIREBASE_FUNCTIONS_EMULATOR_HOST) {
    const url = new URL(`http://${FIREBASE_FUNCTIONS_EMULATOR_HOST}`);
    connectFunctionsEmulator(realFunctions, url.hostname, Number(url.port));
    console.log(`%c[Firebase] Using functions emulator ${url}`, "color: red");
}
export const firestore = getFirestore(app);
if(FIREBASE_FIRESTORE_EMULATOR_ADDRESS) {
    const url = new URL(`http://${FIREBASE_FIRESTORE_EMULATOR_ADDRESS}`);
    connectFirestoreEmulator(firestore, url.hostname, Number(url.port));
    console.log(`%c[Firebase] Using firestore emulator ${url}`, "color: red");
}
export const storage = (bucketUrl?: string) => {
    const tmp = getStorage(app, bucketUrl);
    if(FIREBASE_STORAGE_EMULATOR_HOST) {
        const url = new URL(`http://${FIREBASE_STORAGE_EMULATOR_HOST}`);
        connectStorageEmulator(tmp, url.hostname, Number(url.port));
        console.log(`%c[Firebase] Using storage emulator ${url}`, "color: red");
    }
    return tmp;
}
export const functions = { httpsCallable(funcName: string) {return httpsCallable(realFunctions, funcName)}}
export const analytics = getAnalytics(app);

export { Timestamp } from 'firebase/firestore';

Globals.setFirestore( firestore );

installEmulators({ firestore, functions, auth, config: CONFIG });