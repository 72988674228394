import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card pending-tasks-card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "bold" }
const _hoisted_5 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return ($setup.hasPendingTasks)
    ? (_openBlock(), _createBlock($setup["Modal"], {
        key: 0,
        onClose: $setup.acknowledge
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "pending-tasks-container",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("h2", { class: "mb-0" }, "Pending Task Reminder")
              ], -1)),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.incidentsAndTasks, ([incident, tasks]) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(incident) + ": ", 1),
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tasks, (task) => {
                        return (_openBlock(), _createElementBlock("li", null, _toDisplayString(task.detail), 1))
                      }), 256))
                    ])
                  ]))
                }), 256))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_base_button, {
                  type: "danger",
                  onClick: $setup.acknowledge
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Ok")
                  ])),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}