import type { Units } from "@busy-human/amt-library";
import { realFunctions as functions } from "./firebase";
import { httpsCallable } from 'firebase/functions';

export const updateOCCGrant = httpsCallable<{companyId: string, userId: string, occState: boolean}, {message: 'Success' | 'No change'}>(functions, 'updateOCCGrant')
export const generateRamReport = httpsCallable<{companyId: string, ramReportId: string, emailUsers: boolean}, void>(functions, 'queueRamReport')
export const queueIncidentReport = httpsCallable<{companyId: string, incidentId: string}, void>(functions, 'queueIncidentReport');
export const updatePassword = httpsCallable<{uid: string, password: string}, void>(functions, "updatePassword");
export const deleteUser = httpsCallable<{user: string, deleteRelated?: boolean}, void>(functions, 'deleteUser');
export const updateCrewPhoneNumber = httpsCallable<{crewUserId: string, newPhoneNumber: string}, void>(functions, "updateCrewPhoneNumber");

type UpdateIncidentStatusArgs = {
	companyId: string
	incidentId: string
	unitId?: string
	changedAt?: string
	status: Units.UnitStatus,
	turndownReason?: string,
	reason?: string
	updateReqId?: string | null,
	wasOffline?: boolean | null,
	source?: string | null
}
export const updateIncidentStatus = httpsCallable<UpdateIncidentStatusArgs, string>(functions, "updateIncidentStatus");

//Super Admin Functions
export const encryptPassword = httpsCallable<{password: string}, string>(functions, 'encryptPassword');
export const decryptPassword = httpsCallable<{password: string}, string>(functions, 'decryptPassword');