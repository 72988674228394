import { Company, DataInstance, Tasks } from "@busy-human/amt-library";
import { defineStore } from "pinia";
import { ComputedRef, Ref, computed, onMounted, onScopeDispose, onUnmounted, ref, watch } from "vue";
import { useAuth } from "./useAuth";
import { ISOTimestamp, dataInstanceToData } from "@busy-human/gearbox";
import emitter from "@/events";
import { useIncidents } from "./useIncidents";
import { syncRef } from "@vueuse/core";
import { cloneDeep } from "lodash";

export const useTasks = defineStore('tasks', () => {
    const auth = useAuth();
    const tasks = ref(new Map<string, DataInstance<Tasks.Model>>());
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([]);

    const acknowledgedTasks = ref(new Set<string>());

    watch(() => auth.companyId, async companyId=> {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

		tasks.value.clear();

        if(companyId) {
            const collection = Company.Companies.subCollectionEntity(companyId, 'tasks')
            const subset = collection.all();
            subset.onUpdate(ev => {
                const backup = new Map(tasks.value);
                tasks.value.clear();
                for(const lz of subset.dataItems()) {
                    const existing = backup.get(lz.$id);
                    // If the reminder time changes, make sure we remove it from the acknowledged list
                    if(existing && existing.remindAt !== lz.remindAt) {
                        acknowledgedTasks.value.delete(lz.$id);
                    }
                    tasks.value.set(lz.$id, lz);
                }
                waitingPromises.value.forEach(res => res());
                waitingPromises.value.length = 0;
            })
            await subset.listen();
            loaded.value = true;
            unsub.value = () => {subset.cleanup()}
        }
    }, {immediate: true})

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
    }

    async function addTask(task: Tasks.Model) {
        if(!auth.companyId) throw new Error("Not in company");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'tasks');
        const res = await collection.add(task);
        return res.data();
    }

    async function editTask(id: string, task: Partial<Tasks.Model> | DataInstance<Partial<Tasks.Model>>) {
        if(!auth.companyId) throw new Error("Not in company");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'tasks');
        let data;
        if("$id" in task) data = dataInstanceToData(task);
        else data = task;
        const res = await collection.updateDoc(id, data);
        return res.data();
    }

    async function deleteTask(id: string) {
        if(!auth.companyId) throw new Error("Not in company");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'tasks');
        await collection.deleteDoc(id);
    }

    const asArray = computed(() => [...tasks.value.values()]);

    const now = ref(Date.now());
    function handleTick() {
        now.value = Date.now();
    }
    emitter.on("tick", handleTick);
    // onMounted isn't recommended for pinia store scopes
    onScopeDispose(() => emitter.off("tick", handleTick));
    /** Tasks associated with an active incident. This is done separate from pendingTasks to help with performance. */
    const activeTasks = computed(() => {
        const activeIncidents = useIncidents().activeIncidents.map(i => i.$id);
        return asArray.value.filter(i => activeIncidents.includes(i.incidentId));
    })
    /** List of tasks which are past their reminder and haven't been acknowledged locally. */
    const pendingTasks = computed(() => {
        return activeTasks.value.filter(i => 
            !acknowledgedTasks.value.has(i.$id)
            && i.remindAt
            && i.status !== Tasks.Status.Complete
            && new Date(i.remindAt).getTime() < now.value
        );
    });

    function acknowledgeTask(id: string) {
        acknowledgedTasks.value.add(id);
    }
    function acknowledgeAllTasks() {
        for(const task of pendingTasks.value) {
            acknowledgedTasks.value.add(task.$id);
        }
    }

    return { tasks, loaded, asArray, pendingTasks, acknowledgeTask, acknowledgeAllTasks, waitForReady, addTask, editTask, deleteTask }
})

export function useIncidentTasks(incidentId: Ref<string> | ComputedRef<string>) {
    const taskStore = useTasks();
    const authStore = useAuth();

    /** List of tasks associated with an incident, sorted by creation time */
    const tasks = computed(() => taskStore.asArray
        .filter(t => t.incidentId === incidentId.value)
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    );

    /** Adds a task to the given incident */
    async function addTask(detail: string) {
        const task: Tasks.Model = {
            ... Tasks.Defaults(),
            detail,
            incidentId: incidentId.value
        }

        task.taskChangelog.push({
            key: "status",
            changedAt: new Date().toISOString(),
            userId: authStore.currentUID || "",
            userEmail: authStore.user?.email || "",
            value: task.status 
        });

        const res = await taskStore.addTask(task);

        return res;
    }

    /** INTERNAL: Updates a property of a task */
    function updateTaskProperty<T extends keyof Omit<Tasks.Model, "comments" | "taskChangelog">>(id: string, key: T, value: Tasks.Model[T]) {
        const existing = taskStore.tasks.get(id);
        if(!existing) throw new Error("Task not found");

        const taskChangelog = [...existing.taskChangelog];
        taskChangelog.push({
            key, value,
            changedAt: new Date().toISOString(),
            userId: authStore.currentUID || "",
            userEmail: authStore.user?.email || ""
        })

        return taskStore.editTask(id, {
            [key]: value,
            taskChangelog
        });
    } 

    /** Adds a comment to a task */
    async function addTaskComment(id: string, comment: string) {
        const existing = taskStore.tasks.get(id);
        if(!existing) throw new Error("Task not found");

        const comments = [...existing.comments];
        comments.push({
            createdAt: new Date().toISOString(),
            userId: authStore.currentUID || "",
            userEmail: authStore.user?.email || "",
            comment
        })

        await taskStore.editTask(id, { comments });
    }

    /** Sets the status of a task */
    async function setTaskStatus(id: string, status: Tasks.Status) {
        await updateTaskProperty(id, "status", status);
    }

    /** Deletes a task */
    async function deleteTask(id: string) {
        await taskStore.deleteTask(id);
    }

    /** Updates the details of a task */
    async function updateTaskDetail(id: string, detail: string) {
        await updateTaskProperty(id, "detail", detail);
    }

    /** Sets the reminder time of a task */
    async function setTaskReminder(id: string, remindAt: ISOTimestamp | null) {
        await updateTaskProperty(id, "remindAt", remindAt)
    }

    const now = ref(Date.now());
    function handleTick() {
        now.value = Date.now();
    }
    onMounted(() => emitter.on("tick", handleTick));
    onUnmounted(() => emitter.off("tick", handleTick));

    const incidentReminderActive = computed(() => {
        return tasks.value.reduce((prev, t) => {
            if(prev || !t.remindAt) return prev;
            return (new Date(t.remindAt).getTime() < now.value && t.status !== Tasks.Status.Complete);
        }, false);
    });

    return { tasks, addTask, deleteTask, setTaskStatus, updateTaskDetail, addTaskComment, setTaskReminder, incidentReminderActive }
}


export function useTaskIndividual(taskId: Ref<string | undefined> | ComputedRef<string | undefined>) {
    const taskStore = useTasks();
    const authStore = useAuth();

    const backendTask = computed(() => taskStore.tasks.get(taskId.value || ""));
    const task = ref(Tasks.Defaults());
    
    watch(backendTask, t => {
        if(t) {
            task.value = dataInstanceToData(cloneDeep(t));
        }
    }, {deep: true, immediate: true});

    /** INTERNAL: Commits task details to firestore */
    async function tryCommitDetails() {
        if(taskId.value) {
            await taskStore.editTask(taskId.value, task.value)
        }
    }

    async function save(incidentId?: string) {
        task.value.incidentId = incidentId || "";
        if(taskId.value) {
            await taskStore.editTask(taskId.value, task.value)
        } else {
            await taskStore.addTask(task.value);
        }
    }

    /** INTERNAL: Updates a property of a task */
    function updateProperty<T extends keyof Omit<Tasks.Model, "comments" | "taskChangelog">>(key: T, value: Tasks.Model[T]) {
        task.value.taskChangelog.push({
            key, value,
            changedAt: new Date().toISOString(),
            userId: authStore.currentUID || "",
            userEmail: authStore.user?.email || ""
        })

        task.value[key] = value;
    }

    /** Adds a comment to a task */
    async function addComment(comment: string) {
        task.value.comments.push({
            createdAt: new Date().toISOString(),
            userId: authStore.currentUID || "",
            userEmail: authStore.user?.email || "",
            comment
        })

        await tryCommitDetails();
    }

    /** Sets the status of a task */
    async function setStatus(status: Tasks.Status) {
        await updateProperty("status", status);
    }

    /** Deletes a task */
    async function deleteTask() {
        if(!taskId.value) return;
        await taskStore.deleteTask(taskId.value);
    }

    /** Updates the details of a task */
    async function updateDetail(detail: string) {
        await updateProperty("detail", detail);
    }

    /** Sets the reminder time of a task */
    async function setReminder(remindAt: ISOTimestamp | null) {
        await updateProperty("remindAt", remindAt)
    }

    const comments = computed(() => task.value?.comments || []);
    const changelog = computed(() => task.value?.taskChangelog || []);

    return { task, comments, changelog, addComment, setStatus, deleteTask, updateDetail, setReminder, save }
}