import { defineStore } from "pinia";
import { useAuth } from "./useAuth";
import { computed, ref, watch } from "vue";
import { Company, DataInstance } from "@busy-human/amt-library";

export const useCompany = defineStore('company', () => {
    const auth = useAuth();
    const company = ref<DataInstance<Company.Company>>({...Company.Defaults(), $id: ''});
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([])

    watch(() => auth.companyId, async companyId => {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

		company.value = {
			... Company.Defaults(),
			$id: ''
		}

        if(companyId) {
            const docref = Company.Companies.docEntity(companyId);
            docref.onUpdate(() => {
                company.value = docref.data();
            })
            await docref.listen();
            unsub.value = () => {docref.cleanup()}
        }
        
        loaded.value = true;
        waitingPromises.value.forEach(res => res());
        waitingPromises.value.length = 0;
    }, {immediate: true})

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
    }

    const _company = computed(() => company.value);

    return { waitForReady, company: _company }
})