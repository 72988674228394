import { defineComponent as _defineComponent } from 'vue'
import Timer from "../timer.js"
import TaskWarningOverlay from "@/views/Components/task-modal/task-warning-overlay.vue";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'app',
  setup(__props, { expose: __expose }) {
  __expose();

onMounted(() => {
    (window as any).APPLICATION_LOADER.done();
    Timer.start();
})

const __returned__ = { get Timer() { return Timer }, TaskWarningOverlay, onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})