import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-scrim", [...$props.scrimClasses]]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-contents", [...$props.contentClasses]]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2)
  ]))
}