import { useAuth } from "@/store/useAuth";
import { useCompany } from "@/store/useCompany";
import { Company, Messages } from "@busy-human/amt-library";
import { DataInstance, SnapshotEventType } from "@busy-human/gearbox";
import { computed, onMounted, onUnmounted, ref } from "vue";

export function useMessageUnreadCount() {

    const cleanup = ref<(() => void) | null>(null);
    /** User-viewable counter. Will be empty if 0, and will appear as 9+ if it's 10 or greater */
    const unreadCount = ref<string>("");

    const messages = ref<DataInstance<Messages.Message>[]>([]);

    const unreadPerUnitCount = computed(() => {
        const unitUnreadCount: Record<string, number> = {};

        for(const message of messages.value) {
            if(message.unitId in unitUnreadCount) {
                unitUnreadCount[message.unitId] += 1;
            } else {
                unitUnreadCount[message.unitId] = 1;
            }
        }

        return unitUnreadCount;
    })

    async function setupCountListener() {
        const companyStore = useCompany();
        await companyStore.waitForReady();
        const auth = useAuth();

        const msgs = Company.Companies.subCollectionEntity(companyStore.company.$id, 'messages');

        const subset = msgs.subset((_, query) => query
            .where('usersUnread', 'array-contains', auth.currentUID)
            .toQuery()
        )

        subset.onUpdate(() => {
            messages.value = subset.dataItems();
            const trueCount = messages.value.length;
            if(trueCount === 0) unreadCount.value = "";
            else if(trueCount > 9) unreadCount.value = "9+";
            else unreadCount.value = `${trueCount}`;
        });

        await subset.listen();

        cleanup.value = () => subset.cleanup();
    }

    function cleanupCountListener() {
        cleanup.value?.();
        cleanup.value = null;
    }

    onMounted(async () => {
        await setupCountListener();
    });
    onUnmounted(() => {
        cleanupCountListener();
    })


    return { unreadCount, messages, unreadPerUnitCount };
}