import {ref} from 'vue'
export const notificationList = [
    {
        name: 'Alert 1',
        audioFile: 'alert_1.mp3'
    },
    {
        name: 'Alert 2',
        audioFile: 'alert_2.mp3'
    },
    {
        name: 'Alert 3',
        audioFile: 'alert_3.mp3'
    },
    {
        name: 'Two-tone 1',
        audioFile: 'two_tone_1.mp3'
    },
    {
        name: 'Two-tone 2',
        audioFile: 'two_tone_2.mp3'
    },
    {
        name: 'Two-tone 3',
        audioFile: 'two_tone_3.mp3'
    },
]

export let audioPlaying = ref(false)
let currentAudio = ref(null)

export function playSound(soundIndex){
    if(audioPlaying.value){
        currentAudio.value.pause()
        currentAudio.value = null
        audioPlaying.value = false
    }
    let index = soundIndex
    if((soundIndex != 0 && !soundIndex) || soundIndex >= notificationList.length)
        index = 0

    let sound = notificationList[index]

    play(`../resources/alerts/${sound.audioFile}`).then(()=>{
        audioPlaying.value = false
    })
}

function play(url) {
    audioPlaying.value = true
    return new Promise(function(resolve, reject) { 
      var audio = new Audio();
      currentAudio.value = audio
      audio.preload = "auto";
      audio.autoplay = true;
      audio.onerror = reject;
      audio.onended = resolve;
  
      audio.src = url
    });
}