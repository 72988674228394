import { useAuth } from "@/store/useAuth";
import { useCompany } from "@/store/useCompany";
import { useUsers } from "@/store/useUsers";
import { Company, DataInstance, Messages, Users } from "@busy-human/amt-library";
import { Timestamp, arrayRemove } from "firebase/firestore";
import mitt from "mitt";
import { type Ref, onMounted, onUnmounted, ref, computed } from "vue";
import { ref as storageRef, uploadBytes, getBlob, getDownloadURL } from 'firebase/storage';
import { storage } from "@/util/firebase";
import { useUnits } from "@/store/useUnits";

export function useUnitMessages(unitId: string, preload = true) {

    const companyStore = useCompany();
    const auth = useAuth();
    const units = useUnits();

    const unit = computed(() => units.units.get(unitId));

    const msgs = Company.Companies.subCollectionEntity(companyStore.company.$id, 'messages');

    type Events = {
        "MESSAGE:RECEIVED": DataInstance<Messages.Message>,
        "LOADED": void
    }

    const events = mitt<Events>();

    function addEventHandler<T extends keyof Events>(event: T, handler: (value: Events[T]) => void) {
        events.on(event, handler);
        onUnmounted(() => events.off(event, handler));
    }

    const cleanup = ref<(() => void) | null>(null);
    /** User-viewable counter. Will be empty if 0, and will appear as 9+ if it's 10 or greater */
    const unreadCount = ref<string>("");

    const historicalMessages = ref<DataInstance<Messages.Message>[]>([]);
    const newMessages = ref<DataInstance<Messages.Message>[]>([]);

    const messages = computed(() => [
        ...historicalMessages.value,
        ...newMessages.value
    ])

    // Unread counting
        
    const unreadSubset = msgs.subset((_, query) => query
        .where('unitId', '==', unitId)
        .where('usersUnread', 'array-contains', auth.currentUID)
        .limit(10)
        .toQuery()
    )

    unreadSubset.onUpdate(() => {
        const trueCount = unreadSubset.dataItems().length;
        if(trueCount === 0) unreadCount.value = "";
        else if(trueCount > 9) unreadCount.value = "9+";
        else unreadCount.value = `${trueCount}`;
    });

    // Actual messages

    const PAGE_SIZE = 20;

    const messageSubset = msgs.subset((_, query) => query
        .where('unitId', '==', unitId)
        .orderBy("timestamp", "desc")
        .toQuery()
    ).paginate(PAGE_SIZE);

    messageSubset.onUpdate(() => {
        historicalMessages.value = messageSubset.dataItems().reverse();
        //events.emit("MESSAGE:RECEIVED", messages.value.at(-1)!);
    });

    async function setupMessageListener() {

        await unreadSubset.listen();
        await messageSubset.fetch();
        // await messageSubset.listen();

        const newMessageSubset = msgs.subset((_, query) => query
            .where('unitId', '==', unitId)
            .orderBy("timestamp", "asc")
            .startAfter(messageSubset.getStartAfterQuery())
            .toQuery()
        );

        newMessageSubset.onUpdate(() => {
            newMessages.value = newMessageSubset.dataItems();
            const mostRecent = newMessages.value.at(-1);
            if(mostRecent)
                events.emit("MESSAGE:RECEIVED", mostRecent);
        });

        await newMessageSubset.listen();

        events.emit("LOADED");

        cleanup.value = () => { 
            unreadSubset.cleanup();
            messageSubset.cleanup();
            newMessageSubset.cleanup();
        };
    }

    function cleanupMessageListener() {
        cleanup.value?.();
        cleanup.value = null;
    }

    async function markAllMessagesRead() {
        if(!auth.companyId) return;
        if(!auth.currentUID) return;
        const col = Company.Companies.subCollectionEntity(auth.companyId, 'messages');

        const msgs = await col.subset((_, query) => query
            .where('usersUnread', 'array-contains', auth.currentUID)
            .toQuery()
        ).fetch();

        const tmp = arrayRemove(auth.currentUID) as any;

        const res = await Promise.allSettled(msgs.map(m => 
            m.update({
                usersUnread: tmp
            })
        ));

        for(const r of res) {
            if(r.status === 'rejected') console.error(r.reason);
        }
    }

    async function markMessagesRead() {
        if(!auth.companyId) return;
        if(!auth.currentUID) return;
        const col = Company.Companies.subCollectionEntity(auth.companyId, 'messages');

        const msgs = await col.subset((_, query) => query
            .where('usersUnread', 'array-contains', auth.currentUID)
            .where('unitId', '==', unitId)
            .toQuery()
        ).fetch();

        const tmp = arrayRemove(auth.currentUID) as any;

        const res = await Promise.allSettled(msgs.map(m => 
            m.update({
                usersUnread: tmp
            })
        ));

        for(const r of res) {
            if(r.status === 'rejected') console.error(r.reason);
        }
    }

    onMounted(async () => {
        if(preload) await setupMessageListener();
    });
    onUnmounted(() => {
        cleanupMessageListener();
    });

    const isFetching = ref(false);
    const isExhausted = ref(false);
    async function fetchMore() {
        if(isFetching.value || isExhausted.value) return;
        isFetching.value = true;
        await messageSubset.fetch();
        isFetching.value = false;

        if(messageSubset.isPaginationExhausted) {
            isExhausted.value = true;
        }
    }

    async function sendMessage(message: string, incidentId?: string | null, attachmentNote?: string | null, attachments?: string[] | null) {
        const userStore = useUsers();
        await userStore.waitForReady();
        const current = userStore.currentUser;
        if(!current) throw new Error("You are not a user");
        if(!unit.value) throw new Error("Unit not found");

        const commsUsers = [...userStore.users.values()]
            .filter(u => Users.userTypeIsComm(u.type))
            .map(u => u.$id)
            .filter(u => u !== current.$id);

        const unitUsers = [
            unit.value.pilot?.id, unit.value.ground1?.id, unit.value.ground2?.id,
            unit.value.med1Phone?.id, unit.value.med2Phone?.id, unit.value.mechanicPhone?.id
        ].filter((u): u is string => !!u).filter(u => u !== current.$id);

        const messageData: Messages.Message = {
            ... msgs.defaults(),
            unitId,
            message, 
            sender: current?.name.fullName,
            senderId: current.$id,
            commId: current.$id,
            from: "company",
            timestamp: Timestamp.now(),
            usersUnread: [...commsUsers, ...unitUsers]
        }
        if(incidentId) messageData.incidentId = incidentId;
        if(attachmentNote) messageData.attachmentNote = attachmentNote;
        if(attachments) messageData.attachments = attachments;
        await msgs.add(messageData);
    }

    const str = storage("gs://amt-msg-attachments")

    async function uploadAttachment(file: File) {
        const fileId = crypto.randomUUID();
        const ext = file.name.split(".").pop();
        const companyId = auth.companyId;
        if(!companyId) throw new Error("Not in a company");

        const fileRef = `${companyId}/${fileId}.${ext}`;
        await uploadBytes(storageRef(str, fileRef), file);

        return fileRef;
    }


    return { messages, unreadCount, events, addEventHandler, markMessagesRead, isFetching, isExhausted, fetchMore, sendMessage, uploadAttachment }
}