import { defineComponent as _defineComponent } from 'vue'
interface Props {
    scrimClasses?: string[]
    contentClasses?: string[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    scrimClasses: { default: () => [] },
    contentClasses: { default: () => [] }
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props


const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})