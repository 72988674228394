import { defineStore } from "pinia";
import { onAuthStateChanged, User, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut as logOut, sendPasswordResetEmail, ParsedToken, getIdTokenResult, EmailAuthProvider, linkWithCredential, signInAnonymously, sendEmailVerification } from "firebase/auth"
import { auth } from "@/util/firebase";
import { computed, ref } from "vue";
import type { FirebaseError } from "@firebase/util";
import { setRaygunUserEventGenerator, updateRaygunUser } from "@busy-human/raygun-util";

const AuthErrorMap: {[code: string]: string} = {
    'auth/invalid-email': "Invalid Email",
    'auth/user-not-found': "User Not Found",
    'auth/wrong-password': "Password Invalid",
    'auth/email-already-in-use': "Email Already In Use",
    'auth/too-many-requests': "Too many requests, please try again later",
    'auth/invalid-login-credentials': "Invalid Credentials",
    'auth/missing-password': "Missing password"
}

function ConvertAuthError(errorCode: string) {
    // return AuthErrorMap[errorCode] || "Unknown"
    return AuthErrorMap[errorCode] || errorCode
}

type ClaimsType = {
    isAdmin?: boolean
    userType?: string
    companyId?: string
    superAdmin?: boolean
    roles?: string[]
}

export const useAuth = defineStore('auth', () => {
    const user = ref<User | null>(null);
    const claims = ref<ClaimsType | ParsedToken | null>(null);
    const _authready = ref<boolean>(false);
    const companyOverride = ref<string | null>(null);

    onAuthStateChanged(auth, async userAuth => {
        user.value = userAuth;
        if(userAuth) {
            claims.value = (await getIdTokenResult(userAuth)).claims;
            console.log("Claims", claims.value);
        } else {
            claims.value = null;
        }
        _authready.value = true;
        updateRaygunUser(userAuth);
        console.log(`%cAuth State Changed: ${user.value === null ? "Unauthenticated" : "Authenticated" }`, `font-size: large; color: ${user.value !== null ? 'lime' : 'orange'};font-family: "Comic Sans MS", "Comic Sans", cursive;`);
        authWaitingPromises.value.map(res => res());
        authWaitingPromises.value = [];
    })

    /** Has auth been set up? */
    const isAuthReady = computed(() => _authready.value);
    /** Is the user authenticated? NOTE: This will return true if the user is anonymously authenticated! */
    const isAuthenticated = computed(() => user.value !== null);
    /** Current userId */
    const currentUID = computed(() => user.value?.uid);
    /** Current companyId */
    const companyId = computed(() => companyOverride.value || (typeof claims.value?.companyId === 'string' ? claims.value.companyId : null));
    /** Is super admin */
    const isSuperAdmin = computed(() => !!claims.value?.superAdmin);

    /** Signs a user in using and email and password */
    async function signIn(email: string, password: string) {
        try {
            const creds = await signInWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
        } catch (e) {
            console.error(e);
            throw new Error(ConvertAuthError((e as FirebaseError).code));
        }
    }

    /** Requests a password reset email */
    async function sendPasswordReset(email: string) {
        return sendPasswordResetEmail(auth, email)
    }

    /** Sign out */
    async function signOut() {
        try {
            await logOut(auth);
            // localStorage.clear();
        } catch (e) {
            console.error(e);
        }
    }

    /** Reload user claims from firebase */
    async function reloadClaims() {
        const token = await user.value?.getIdTokenResult(true);
        claims.value = token?.claims || null;
    }

    setRaygunUserEventGenerator((userData) => {
        if(userData) {
            if(userData.isAnonymous) {
                return {
                    identifier: userData.uid,
                    email: '',
                    isAnonymous: true
                }
            } else {
                return {
                    identifier: userData.uid,
                    email: userData.email || '',
                    isAnonymous: false
                }
            }
        } else {
            return {
                identifier: '',
                email: '',
                isAnonymous: true
            }
        }
    })

    const authWaitingPromises = ref<(() => void)[]>([]);
    /** Promise that resolves when auth is ready, or immediately if already ready */
    async function waitForAuth() {
        if(isAuthReady.value) return;
        else {
            return new Promise<void>(res => authWaitingPromises.value.push(res));
        }
    }

    async function performCompanyOverride(companyId: string | null) {
        if(!isSuperAdmin.value) return;
        companyOverride.value = companyId;
    }

    const isOCC = computed(() => {
        if(claims.value?.userType === 'occ' || (Array.isArray(claims.value?.roles) && claims.value?.roles.includes('occ'))) return true;
        return false;
    })

    return { user, isAuthReady, isAuthenticated, signIn, signOut, sendPasswordReset, reloadClaims, waitForAuth, claims, currentUID, companyId, isSuperAdmin, performCompanyOverride, isOCC }
})