import { Company, DataInstance, Incident, Units, Users } from "@busy-human/amt-library";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useAuth } from "./useAuth";
import { useUnits } from "./useUnits";
import { useCrewMembers } from "./useCrewMembers";
import haversine from 'haversine-distance'
import { cloneDeep } from "lodash";
import { useUsers } from "./useUsers";
import { onSnapshot } from 'firebase/firestore'
import { ISOTimestamp, UnixTimestamp } from "@busy-human/gearbox";
import { queueIncidentReport } from "@/util/functions";

type IncidentChange = {
    status: Units.UnitStatus
    unit?: string
    userId: string
    /** Timestamp */
    start: number
    /** Timestamp */
    end?: number
    turndownReason?: string
    reason?: string
    position?: "coordinates"
    companyNotified: boolean

    incidentId: string
    logIdx: number
    incidentNumber: string
    
}

type userAck = {
    name: string
    incidentNumber: string
    timestamp: string
}

export const useIncidents = defineStore('incidents', () => {
    const auth = useAuth();
    const users = useUsers();
    const incidents = ref(new Map<string, DataInstance<Incident.Incident>>());
    /** Maps unit Ids to incident Ids */
    const unitIncident = ref(new Map<string, string>());
    const incidentChanges = ref(new Map<string, IncidentChange>());
    const unitUserAcks = ref<userAck[]>([]);
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([])

    function getCollection() {
        const auth = useAuth();
        if(!auth.companyId) throw new Error("No companyId!");
        return Company.Companies.subCollectionEntity(auth.companyId, 'incidents')
    }

    watch(() => auth.companyId, async companyId => {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

        if(companyId) {
            const collection = Company.Companies.subCollectionEntity(companyId, 'incidents')
            const subset = collection.all();

            unsub.value = onSnapshot(subset.ref(), next => {
                for(const docChange of next.docChanges()) {

                    const incident: DataInstance<Incident.Incident> = {
                        $id: docChange.doc.id,
                        ...docChange.doc.data() as Incident.Incident
                    }

                    if(incident.statusLog){
                        const users = useUsers()
                        const currentUserId = users.currentUser?.$id

                        incident.statusLog?.forEach((status, idx) => {
                            if(status.companyNotified === false && (currentUserId && status.userId !== currentUserId)){
                                let incidentChange = incidentChanges.value.get(incident.$id)

                                if(!incidentChange || incidentChange.logIdx !== idx || incidentChange.start !== status.start){
                                    let newLog: IncidentChange = {
                                        ...status,
                                        incidentId: incident.$id,
                                        incidentNumber: incident.number,
                                        logIdx: idx
                                    }

                                    incidentChanges.value.set(incident.$id, newLog)
                                }
                            }
                        })
                    }

                    // unit ack stuff
                    const before = incidents.value.get(incident.$id);
                    if(before?.unitAck && incident.unitAck) {
                        for(const [userId, ack] of Object.entries(before.unitAck)) {
                            if(!ack && userId in incident.unitAck && incident.unitAck[userId]) {
                                const user = users.users.get(userId);
                                unitUserAcks.value.push({
                                    name: Users.resolveUserName(user),
                                    incidentNumber: incident.number,
                                    timestamp: new Date().toISOString()
                                })
                            } else {

                            }
                        }
                    }

                    if(docChange.type === 'removed') {
                        if(incident.assignedUnit && unitIncident.value.get(incident.assignedUnit) === incident.$id){
                            unitIncident.value.delete(incident.assignedUnit);
                        }
                        incidents.value.delete(docChange.doc.id);
                    } else {
                        incidents.value.set(incident.$id, incident);
                        if(incident.assignedUnit && incident.active) {
                            unitIncident.value.set(incident.assignedUnit, incident.$id);
                        }else if(incident.assignedUnit && !incident.active && unitIncident.value.get(incident.assignedUnit) === incident.$id){
                            unitIncident.value.delete(incident.assignedUnit);
                        }

                        //Remove unitIncidents referencing incident if there is none assigned
                        if(!incident.assignedUnit && [...unitIncident.value.values()].includes(incident.$id)){
                            [...unitIncident.value.keys()].forEach((unitId) => {
                                if(unitIncident.value.get(unitId) === incident.$id){
                                    unitIncident.value.delete(unitId);
                                }
                            })
                        }
                    }
                }
                loaded.value = true;
                waitingPromises.value.forEach(res => res());
                waitingPromises.value.length = 0;
            }) 
        } else {
            incidents.value.clear();
        }
    }, { immediate: true })

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
        else return Promise.resolve()
    }

    async function updateIncident(incidentId: string, incidentDetails: Partial<Incident.Incident>){
        if(!auth.companyId) throw new Error("no companyId!");

        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'incidents');
        await collection.updateDoc(incidentId, incidentDetails)
    }

    async function declineIncident(incidentId: string, turndownReason: string) {
        const auth = useAuth();
        if(!auth.currentUID) throw new Error('not logged in!')
    
        const incident = incidents.value.get(incidentId);

        if(!incident) throw new Error(`No such incident ${incidentId}`);
        const prevId = incident.assignedUnit;

        const statusLog: Incident.StatusLogRecord[] = cloneDeep(incident.statusLog || []);
        if(statusLog.length > 0) {
            statusLog[statusLog.length - 1].end = Date.now();
        }
        const item: Incident.StatusLogRecord = {
            status: Units.UnitStatus.Declined,
            start: Date.now(),
            userId: auth.currentUID,
            userEmail: auth.user?.email || '',
            companyNotified: false,
            turndownReason
        }
        if(incident.assignedUnit) item.unit = incident.assignedUnit;
        if(auth.user?.email) item.userEmail = auth.user.email;
        statusLog.push(item)
    
        const collection = getCollection();

        if(prevId) unitIncident.value.delete(prevId); 

        const tmp: Partial<Incident.Incident> = {
            assignedUnit: "",
            statusLog,
        }
        
        await collection.updateDoc(incidentId, tmp)
    }

    async function updateStatus(incidentId: string, status: Units.UnitStatus, unitId?: string, turndownReason?: string, rescheduleTime?: ISOTimestamp) {
        const unitStore = useUnits()
        const crewStore = useCrewMembers();
        const auth = useAuth();
        if(!auth.currentUID) throw new Error('not logged in!')
    
        const incident = incidents.value.get(incidentId);

        if(!incident) throw new Error(`No such incident ${incidentId}`);
        const prevId = incident.assignedUnit;
        const unit = unitId ? unitStore.units.get(unitId) : undefined;
        const pilot = crewStore.crewMembers.get(unit?.pilot?.id || '');
    
        let distance: number | null = null;
        if(pilot?.location && incident.coordinates) {
            distance = haversine(pilot.location, incident.coordinates) / 1609.34;
        }
    
        const onCreateETA = (unit?.liftTime && distance) ? Math.ceil(unit.liftTime + distance / 2) : null;
        const statusLog: Incident.StatusLogRecord[] = cloneDeep(incident.statusLog || []);
        if(statusLog.length > 0) {
            statusLog[statusLog.length - 1].end = Date.now();
        }
        const item: Incident.StatusLogRecord = {
            status,
            start: Date.now(),
            userId: auth.currentUID,
            userEmail: auth.user?.email || '',
            companyNotified: false
        }
        if(auth.user?.email) item.userEmail = auth.user.email;
        if(unitId) item.unit = unitId;
        if(turndownReason) item.turndownReason = turndownReason
        statusLog.push(item)
    
        const collection = getCollection();

        if(prevId !== unitId && prevId) unitIncident.value.delete(prevId); 

        const tmp: Partial<Incident.Incident> = {
            assignedUnit: unitId || "",
            statusLog,
            onCreateETA
        }
        if(rescheduleTime) tmp.scheduledTime = rescheduleTime;
        
        await collection.updateDoc(incidentId, tmp)
    }

    async function reopenIncident(incidentId: string) {
        const incident = incidents.value.get(incidentId);
        if(!incident) throw new Error(`No such incident: ${incidentId}`);
        const statusLog = incident.statusLog || []
        const now = Date.now();
        if(statusLog.length > 0) statusLog[statusLog.length - 1].end = now;
        statusLog.push({
            start: now,
            status: Units.UnitStatus.Pending,
            userId: auth.currentUID!,
            companyNotified: false,
            userEmail: auth.user?.email || ''
        });

        incident.assignedUnitOverride = null;
        
        const collection = getCollection();
        await collection.updateDoc(incidentId, {
            active: true,
            assignedUnit: null,
            assignedUnitOverride: null,
            statusLog

        })
    }

    type UnitSnapshot = {
        pilotId?: string | null,
        sicId?: string | null,
        med1Id?: string | null,
        med2Id?: string | null,
        mechId?: string | null,
        tailNumber?: string
        createdBy: string
    }

    async function overrideIncidentUnit(incidentId: string, snapshot: UnitSnapshot) {
        const incident = incidents.value.get(incidentId);
        if(!incident) throw new Error(`No such incident: ${incidentId}`);

        snapshot.createdBy = auth.currentUID!;
        incident.assignedUnitOverride = snapshot;
        
        const collection = getCollection();
        await collection.updateDoc(incidentId, {
            assignedUnitOverride: snapshot
        })
    }

    async function setLoadedMiles(incidentId: string, loadedMiles: number){
        const collection = getCollection();
        await collection.updateDoc(incidentId, {loadedMiles})
    }

    async function closeIncident(incidentId: string, status: Units.UnitStatus, unitId?: string, reason?: string, turnoverCompany?: string) {
        const auth = useAuth();
        if(!auth.currentUID) throw new Error('not logged in!')
    
        const incident = incidents.value.get(incidentId);

        if(!incident) throw new Error(`No such incident ${incidentId}`);
        const statusLog: Incident.StatusLogRecord[] = cloneDeep(incident.statusLog || []);
        if(statusLog.length > 0) {
            statusLog[statusLog.length - 1].end = Date.now();
        }
        const item: Incident.StatusLogRecord = {
            status,
            start: Date.now(),
            userId: auth.currentUID,
            userEmail: auth.user?.email || '',
            companyNotified: false,
        }
        if(reason) item.reason = reason;
        if(unitId) {
            item.unit = unitId;
            unitIncident.value.delete(unitId);
        }
        statusLog.push(item)

        const collection = getCollection();

        const incidentChanges: Partial<Incident.Incident> = {
            active: false,
            statusLog,
        }
        if(turnoverCompany) incidentChanges.missedFlightTurnoverCompany = turnoverCompany

        if(unitId) incidentChanges.assignedUnit = unitId

        await collection.updateDoc(incidentId, incidentChanges)
    }

    async function generateAndSendReport(incidentId: string) {
        const companyId = auth.companyId;
        if(!companyId) throw new Error("No companyId!!");

        await queueIncidentReport({incidentId, companyId});
    }

    const activeIncidents = computed(() => [...incidents.value.values()].filter(val => val.active && val.assignedUnit));
    const pendingIncidents = computed(() => [...incidents.value.values()].filter(val => val.active && !val.assignedUnit));

    const amtIncidents = computed(() => [...incidents.value].filter(([_key, incident]) => incident.amtInfo && !incident.amtInfo.responded).map(([_key, val]) => val));

    async function amtRespond(incidentId: string) {
        const incident = incidents.value.get(incidentId);
        if(!incident) throw new Error(`Incident ${incidentId} does not exist`);
        if(!incident.amtInfo) throw new Error(`Incident ${incident} does not have amt info`);

        const collection = getCollection();

        const amtInfo = {...incident.amtInfo!, responded: true};

        await collection.updateDoc(incidentId, { amtInfo })
    }

    async function addPosition(incidentId: string, position: string) {
        const collection = getCollection();
        const incident = incidents.value.get(incidentId);
        if(!incident) throw new Error(`Incident ${incidentId} does not exist`);
        await collection.updateDoc(incidentId, {positionLog: [
            ...incident.positionLog || [],
            {
                position,
                timeStamp: new Date().toISOString(),
                user:  auth.currentUID || '',
            }
        ]})
    }

    async function deletePosition(incidentId: string, i: number) {
        const collection = getCollection();
        const incident = incidents.value.get(incidentId);
        if(!incident) throw new Error(`Incident ${incidentId} does not exist`);
        await collection.updateDoc(incidentId, { positionLog: [
            ...incident.positionLog.filter((_v, index) => index !== i)
        ]});
    }

    async function addComment(incidentId: string, comment: string) {
        const collection = getCollection();
        const incident = incidents.value.get(incidentId);
        const user = users.currentUser
        if(!incident) throw new Error(`Incident ${incidentId} does not exist`);
        await collection.updateDoc(incidentId, {comments: [
            ...incident.comments || [],
            {
                comment,
                timeStamp: new Date().toISOString(),
                createdBy: {
                    id: auth.currentUID || '',
                    name: users.currentUser?.name || { firstName: "Unknown", lastName: "Unknown", fullName: "Unknown"}
                }
            } 
        ]})
    }

    async function deleteComment(incidentId: string, i: number) {
        const collection = getCollection();
        const incident = incidents.value.get(incidentId);
        if(!incident) throw new Error(`Incident ${incidentId} does not exist`);
        await collection.updateDoc(incidentId, { comments: [
            ...(incident.comments || []).filter((_v, index) => index !== i)
        ]});
    }

    async function occRespond(incidentId: string, approved: boolean, signatureUrl: string) {
        const collection = getCollection();
        collection.updateDoc(incidentId, {
            occResponse: {
                approved,
                signatureUrl,
                timeStamp: new Date().toISOString(),
                createdBy: {
                    id: auth.currentUID || '',
                    name: users.currentUser!.name
                }
            }
        })
    }

    function removeIncidentChange(incidentId: string){
        incidentChanges.value.delete(incidentId)
    }

    async function deleteIncident(incidentId: string) {
        const collection = getCollection();
        await collection.deleteDoc(incidentId);
    }

    async function addIncident(incident: Incident.Incident) {
        const collection = getCollection();
        const res = await collection.add(incident);
        return res;
    }

    return { incidents, incidentChanges, activeIncidents, pendingIncidents, loaded, waitForReady, 
        updateIncident, updateStatus, closeIncident, removeIncidentChange, generateAndSendReport, 
        unitIncident, amtIncidents, amtRespond, addPosition, addComment, occRespond,
        deleteComment, deletePosition, declineIncident, deleteIncident,
        getCollection, reopenIncident, overrideIncidentUnit, setLoadedMiles,
        unitUserAcks, addIncident
    }
}) 