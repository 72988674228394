import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useTitle = defineStore('title', () => {
    const title = ref("AMT Launch");
    const prefix = ref("AMT Launch");
    
    watch(title, t => {
        document.title = t ? `${prefix.value} | ${t}` : "AMT Launch";
    });

    return {title};
});