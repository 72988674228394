import { Company, DataInstance, Reports, Units } from "@busy-human/amt-library";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useAuth } from "./useAuth";
import { onSnapshot } from "firebase/firestore";

export const useOOSReports = defineStore('oosreports', () => {
    const auth = useAuth();
    const oosReports = ref(new Map<string, DataInstance<Reports.OOSReport>>());
    const loaded = ref(false);
    const unsub = ref<(() => void) | null>(null)

    const waitingPromises = ref<(() => void)[]>([])

    watch(() => auth.companyId, async companyId => {
        loaded.value = false;
        if(unsub.value) unsub.value();
        unsub.value = null;

        if(companyId) {
            const collection = Company.Companies.subCollectionEntity(companyId, 'oosReports')
            const subset = collection.all();

            unsub.value = onSnapshot(subset.ref(), next => {
                for(const change of next.docChanges()) {
                    if(change.type === 'removed') {
                        oosReports.value.delete(change.doc.id);
                    } else {
                        const report: DataInstance<Reports.OOSReport> = {
                            $id: change.doc.id,
                            ...change.doc.data() as Reports.OOSReport
                        }
                        oosReports.value.set(report.$id, report);
                    }
                }
                waitingPromises.value.forEach(res => res());
                waitingPromises.value.length = 0;
            })
        } else {
            oosReports.value.clear();
        }
    }, {immediate: true})

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => waitingPromises.value.push(res))
    }

    async function addReport(unitId: string, status: Units.UnitStatus) {
        if(!auth.companyId) throw new Error("No companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'oosReports')
        const newDoc = await collection.add({unit: unitId, startTime: new Date().toISOString(), oosStatus: status, userId: auth.currentUID});
        return newDoc.$id;
    }

    async function endReport(oosId: string) {
        if(!auth.companyId) throw new Error("No companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'oosReports')
        await collection.updateDoc(oosId, {endTime: new Date().toISOString()});
    }

    async function addReportNote(oosId: string, note: string) {
        if(!auth.companyId) throw new Error("No companyId!");
        const collection = Company.Companies.subCollectionEntity(auth.companyId, 'oosReports')
        await collection.updateDoc(oosId, {note: note});
    }

    return { oosReports, loaded, waitForReady, addReport, endReport, addReportNote }
}) 