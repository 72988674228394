import { createApp } from "vue";
import AppComponent from "./document/app.vue";
import { initRaygun, setTags } from "@busy-human/raygun-util";

const app = createApp(AppComponent);

const raygunKey = CONFIG.raygun.key;
initRaygun(raygunKey, { Vue:app, version:VERSION, environment:ENVIRONMENT });
setTags([`platform:${PLATFORM}`, `environment:${ENVIRONMENT}`]);

export {app};
export default app;
